import React, {useState, useEffect} from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  notification,
  Steps,
} from 'antd';
import {
  createWhatsappMetaTemplateService,
  getWhatsappTemplateService, updateWhatsappMetaTemplateService,
} from 'services/dashboard/WhatsappService';
import {WhatsappTemplateCreateResponse} from 'models/responses';
import ChatPreviewPage from "../WhatsappTemplatePreview";
import {
  WhatsappTemplateCreateRequest,
  WhatsappTemplateUpdateRequest
} from "../../../../models/requests";
import {WhatsappMetaWabaLists, WhatsappTemplateDetails} from "../../../../models";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import ErrorBoundaryCustom from "../../../../layouts/errorBoundaryCustom";

export interface WhatsappMetaTemplateFormProps {
  loadingTemplate?: boolean;
  showWhatsappTemplateForm: boolean
  onSave?: (response: WhatsappTemplateCreateResponse[], isNew: boolean) => void,
  onClose?: () => void,
  editItem?: WhatsappTemplateDetails;
  fetchWabaId?: () => void;
  wabaIds?: WhatsappMetaWabaLists;
}

const WhatsappMetaTemplateForm = (props: WhatsappMetaTemplateFormProps) => {
    const [form] = Form.useForm();
    const [buttonType, setButtonType] = useState('');
    const [otpButtonType, setOtpButtonType] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const {showWhatsappTemplateForm, editItem, onClose, onSave, fetchWabaId, loadingTemplate, wabaIds} = props;
    const [loading, setLoading] = useState(false);
    const [headerType, setHeaderType] = useState<string>('');
    const [headerText, setHeaderText] = useState('');
    const [headerFormat, setHeaderFormat] = useState('');
    const [bodyText, setBodyText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [formData, setFormData] = useState({"template_type":"", "category":""});
    // const [buttonType, setButtonType] = useState('');
    const [actionType, setActionType] = useState('');
    const [exampleHeaderValues, setExampleHeaderValues] = useState<any>({});
    const [exampleBodyValues, setExampleBodyValues] = useState<any>({});
    const [buttonTextValues, setButtonTextValues] = useState<string[]>([]);
    const [buttonPhoneTextValues, setButtonPhoneTextValues] = useState<string[]>([]);
    const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
    const [buttonUrlValues, setButtonUrlValues] = useState<string[]>([]);
    const [buttonPhoneValues, setButtonPhoneValues] = useState<string[]>([]);
    const [buttonExampleUrlValues, setButtonExampleUrlValues] = useState<string[]>([]);
    const [fieldsAction] = useState([{key: 'initial_key'}]);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [hasExpiring, setHasExpiring] = useState(false);
    const [isTemplateTypeChange, setIsTemplateTypeChange] = useState(false);
    const [isCategoryTypeChange, setIsCategoryTypeChange] = useState(false);
    const [templateType, setTemplateType] = useState<string>();
    const [buttonCopyCodeTextValues, setButtonCopyCodeTextValues] = useState<string[]>([]);
    const [cardData, setCardData] = useState([]);
    const [mainSave, setMainSave] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
    const [inputDirection, setInputDirection] = useState('ltr');
    const {Step} = Steps;

    const resetAndCloseForm = () => {
      form.resetFields();
      setSelectedCategory(undefined);
      onClose?.();
    };
    const showErrorNotification = (message: string) => {
      const notificationParam = {
        message,
        description: ''
      };
      notification.error(notificationParam);
    };
    const showSuccessNotification = (message: string) => {
      const notificationParam = {
        message,
        description: ''
      };
      notification.success(notificationParam);
    };
    const onOkModel = () => {
      if (!loading) {
        resetAndCloseForm();
        setButtonTextValues([]);
        setButtonPhoneTextValues([]);
        setButtonUrlTextValues([]);
        setButtonUrlValues([]);
        setButtonPhoneValues([]);
        setButtonExampleUrlValues([]);
        setUploadingFile(false);
        setHasExpiring(false);
        setTemplateType(undefined);
        setButtonCopyCodeTextValues([]);
        setCardData([]);
        setSelectedCategory(undefined);
      }
    };

    const onCancelModel = () => {
      if (!loading) {
        resetAndCloseForm();
        setCurrentStep(1);
        resetAndCloseForm();
        setHeaderType('');
        setHeaderText('');
        setHeaderFormat('');
        setBodyText('');
        setExampleHeaderValues({});
        setExampleBodyValues({});
        setFooterText('');
        setButtonType('');
        resetAndCloseForm();
        setButtonType('');
        setActionType('');
        setButtonTextValues([]);
        setButtonPhoneTextValues([]);
        setButtonUrlTextValues([]);
        setButtonUrlValues([]);
        setButtonPhoneValues([]);
        setButtonExampleUrlValues([]);
        setUploadingFile(false);
        setHasExpiring(false);
        setTemplateType(undefined);
        setButtonCopyCodeTextValues([]);
        setCardData([]);
        setSelectedCategory(undefined);
      }
    };

    const onSuccessSaveWhatsappTemplate = (response: WhatsappTemplateCreateResponse[]) => {
      let type = 'created';
      let isNew = true;
      if (editItem) {
        type = 'updated';
        isNew = false;
      }
      showSuccessNotification(`WhatsappTemplate ${type}`);
      setLoading(false);
      setCurrentStep(1);
      resetAndCloseForm();
      setHeaderType('');
      setHeaderText('');
      setHeaderFormat('');
      setBodyText('');
      setExampleHeaderValues({});
      setExampleBodyValues({});
      setFooterText('');
      setButtonType('');
      setActionType('');
      setButtonTextValues([]);
      setButtonPhoneTextValues([]);
      setButtonUrlTextValues([]);
      setButtonUrlValues([]);
      setButtonPhoneValues([]);
      setButtonExampleUrlValues([]);
      setUploadingFile(false);
      setHasExpiring(false);
      setTemplateType(undefined);
      setButtonCopyCodeTextValues([]);
      setCardData([]);
      setSelectedCategory(undefined);
      onSave?.(response, isNew);

    };

    const onErrorSaveWhatsappTemplate = (message: string) => {
      showErrorNotification(message);
      setLoading(false);
    };

    const updateWhatsappTemplate = async (templateId: number, templateRequest: WhatsappTemplateUpdateRequest) => {
      setLoading(true);
      try {
        const response = await updateWhatsappMetaTemplateService(templateRequest, templateId);
        if (response.id) {
          setLoading(false);
          // @ts-ignore
          onSuccessSaveWhatsappTemplate(response);
        } else {
          setLoading(false);
          onErrorSaveWhatsappTemplate("Failed to save Whatsapp Template");
        }
      } catch (error: any) {
        setLoading(false);
        onErrorSaveWhatsappTemplate(error.message);
      }
    };
    const createWhatsappTemplate = async (templateRequest: WhatsappTemplateCreateRequest) => {
      setLoading(true);
      try {
        const response = await createWhatsappMetaTemplateService(templateRequest);
        if (response[0].id) {
          setLoading(false);
          onSuccessSaveWhatsappTemplate(response);
        } else {
          setLoading(false);
          onErrorSaveWhatsappTemplate("Failed to save whatsapp template!");
        }
      } catch (error: any) {
        setLoading(false);
        onErrorSaveWhatsappTemplate(error.message);
      }
    };

    const checkWhatsappTemplate = async (templateName: string) => {
      setLoading(true);
      try {
        const response = await getWhatsappTemplateService(templateName);
        if (response) {
          onErrorSaveWhatsappTemplate("Template Name already exists!");
          setCurrentStep(1);
        } else {
          setCurrentStep(currentStep + 1);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        onErrorSaveWhatsappTemplate(error.message);
      }
    };

    const onSubmitForm = async (values: {
      "waba_id": string,
      "template_name": string,
      "template_language": string[],
      "category": string,
      allow_category_change: boolean,
      "header_text": any,
      "headerText": any,
      "header_format": string,
      "header_type": string,
      "media_url": any,
      "example_header_text": any,
      "exampleHeaderValues": any,
      "body_text": any,
      "bodyText": any,
      "example_body_text": any,
      "exampleBodyValues": any,
      "cardData": any,
      "body_add_security_recommendation": false,
      "footer_text": string,
      "code_expiration_minutes": number,
      "message_send_ttl_seconds": number,
      "limited_time_offer": {
        "limited_time_offer_text": string,
        "has_expiration": boolean
      },
      "buttons_type": any,
      "buttonType": any,
      "otpButtonType": any,
      "button_text_quick_reply": string,
      "actionType": string,
      "button_text_phone_number": string,
      "button_text_url": string,
      "fieldsAction": string,
      "buttons": {
        "buttons_type": string[],
        "button_quick_reply": {
          "button_text_quick_reply": string
        },
        "button_phone_number": {
          "button_text_phone_number": string,
          "button_phone_number": string
        },
        "button_url": {
          "button_text_url": string,
          "button_url": string,
          "example_url_values": string
        },
        "button_Otp": {
          "button_test_otp": string,
          "otp_type": string,
          "otp_autofill_text": string,
          "otp_package_name": string,
          "otp_signature_hash": string,
        },
        "button_copy_code": {
          "button_text_copy_code": string
        }
      }
    }) => {
      setLoading(true);
      try {
        // let WhatsappButtons;
        // const updatedValuesButton = {...values};

        // if (updatedValuesButton.buttons) {
        // if (updatedValuesButton.buttonType === 'QUICK_REPLY') {
        //   updatedValuesButton.buttons.buttons_type = ['QUICK_REPLY'];
        // } else if (updatedValuesButton.buttonType === 'None') {
        //   updatedValuesButton.buttons.buttons_type = [];
        // } else if (updatedValuesButton.buttonType === 'COPY_CODE') {
        //   updatedValuesButton.buttons.buttons_type = ['copy_code'];
        // } else if (updatedValuesButton.actionType === 'PHONE_NUMBER') {
        //   updatedValuesButton.buttons.buttons_type = ['PHONE_NUMBER'];
        // } else if (updatedValuesButton.actionType === 'URL') {
        //   updatedValuesButton.buttons.buttons_type = ['URL'];
        // } else if (updatedValuesButton.actionType === 'PHONE_NUMBER_AND_URL') {
        //   updatedValuesButton.buttons.buttons_type = ['URL', 'PHONE_NUMBER'];
        // }
        //   WhatsappButtons = values.buttons ? {
        //     buttons_type: values.buttons.buttons_type || null,
        //     button_quick_reply: values.buttons && values.buttons.button_quick_reply ? values.buttons.button_quick_reply : null,
        //     button_phone_number: values.buttons && values.buttons.button_phone_number ? values.buttons.button_phone_number : null,
        //     // @ts-ignore
        //     button_copy_code: values.buttons && values.buttons.button_copy_code && values.buttons.button_copy_code[0] !== "" ? values.buttons.button_copy_code : null,
        //     button_url: values.buttons && values.buttons.button_url ? values.buttons.button_url : null,
        //     button_Otp: values.buttons && values.buttons.button_Otp ? values.buttons.button_Otp : null
        //   } : undefined;
        // }
        let exampleBodyTextArray: any = {};
        let exampleHeaderTextArray: any = {};
        if (values.exampleHeaderValues && values.exampleHeaderValues[1] !== "") {
          exampleHeaderTextArray= values.exampleHeaderValues || null;
        }
        if (values.exampleBodyValues && values.exampleBodyValues[1] !== "") {
          exampleBodyTextArray= values.exampleBodyValues || null;
        }

        if (editItem) {
          const updatedValues = {
            // ...values,
            header_text: values?.headerText || null,
            header_format: values.header_format === 'None' ? null : values.header_format || values.header_type,
            media_url: values?.header_format !== 'None' ? values?.media_url || null : null,
            example_header_text: exampleHeaderTextArray || null,
            body_text: values?.bodyText || null,
            example_body_text: exampleBodyTextArray || null,
            body_add_security_recommendation: values.body_add_security_recommendation || false,
            footer_text: values.footer_text || null,
            code_expiration_minutes: values.code_expiration_minutes || null,
            message_send_ttl_seconds: values.message_send_ttl_seconds || null,
            limited_time_offer: values.limited_time_offer ? {
              limited_time_offer_text: values.limited_time_offer.limited_time_offer_text || null,
              has_expiration: values.limited_time_offer.has_expiration || null
            } : null,
            carousel: values.cardData.length !== 0 ? {components: values?.cardData} : null,
            buttons: values?.buttons_type !== 'None' ? values.buttons : null
          };
          await updateWhatsappTemplate(editItem?.id ?? '', updatedValues);

        } else {
          const createRequest = {
            waba_id: values.waba_id,
            template_name: values.template_name,
            template_language: values.template_language,
            allow_category_change: values.allow_category_change,
            category: values.category,
            header_text: values?.headerText || null,
            header_format: values.header_format === 'None' ? null : values.header_format || values.header_type,
            media_url: values?.header_format !== 'None' ? values?.media_url || null : null,
            example_header_text: exampleHeaderTextArray || null,
            body_text: values.bodyText || null,
            example_body_text: exampleBodyTextArray || null,
            carousel: values.cardData.length !== 0 ? {components: values?.cardData} : null,
            body_add_security_recommendation: values.body_add_security_recommendation || false,
            footer_text: values.footer_text || null,
            code_expiration_minutes: values.code_expiration_minutes || null,
            message_send_ttl_seconds: values.message_send_ttl_seconds || null,
            limited_time_offer: values.limited_time_offer ? {
              limited_time_offer_text: values.limited_time_offer.limited_time_offer_text || null,
              has_expiration: values.limited_time_offer.has_expiration || null
            } : null,
            buttons: values?.buttons_type !== 'None' ? values?.buttons : null
          };
          await createWhatsappTemplate(createRequest);
        }
      } catch (error: any) {
        showErrorNotification(error.message);
      }
    };


    const onNextStep = async (additionalValues?: any) => {

      form.validateFields()
        .then(async values => {
          const newFormData = {...formData, ...values, ...additionalValues};

          if (templateType === formData.template_type && selectedCategory !== 'AUTHENTICATION') {
            setIsTemplateTypeChange(false);
          }
          if (selectedCategory === formData.category) {
            setIsCategoryTypeChange(false);
          }
          if (newFormData.category === 'AUTHENTICATION') {
            if (currentStep === 3)
              if (!newFormData.otpButtonType) {
                onErrorSaveWhatsappTemplate("Message templates in the AUTHENTICATION category must have exactly one button.");
                return;
              }
            // Set otp_type directly in newFormData.buttons[0]
            if (additionalValues.otpButtonType) {
              newFormData.buttons.button_Otp = [{
                otp_type: additionalValues?.otpButtonType || "",
                button_text_otp: values.buttons?.button_Otp[0]?.button_text_otp || '',
                otp_autofill_text: values.buttons?.button_Otp[0]?.otp_autofill_text || '',
                otp_package_name: values.buttons?.button_Otp[0]?.otp_package_name || '',
                otp_signature_hash: values.buttons?.button_Otp[0]?.otp_signature_hash || '',
                zero_tap_terms_accepted: values.buttons?.button_Otp[0]?.zero_tap_terms_accepted || null
              }];
            }
          }

          if (currentStep === 2 && (newFormData.template_type === 'LIMITED_TIME_OFFER')) {
            if (newFormData.limited_time_offer.has_expiration) {
              setHasExpiring(true);
            } else {
              setHasExpiring(false);
            }
          }

          if (currentStep < 3) {
            if (currentStep === 1 && !editItem) {
              checkWhatsappTemplate(values.template_name);
            } else {
              setCurrentStep(currentStep + 1);
            }
            setFormData(newFormData);
          } else {
            if (newFormData.template_type === 'CAROUSEL') {
              if (!mainSave) {
                showErrorNotification('Please save the current card to continue');
                return;
              }
              newFormData.buttons = null;
            }

            if (newFormData.template_type === 'CUSTOM' || newFormData.template_type === 'LIMITED_TIME_OFFER') {
              const buttonQuickReply = newFormData.buttonTextValues && newFormData.buttonTextValues.length > 0
                ? newFormData.buttonTextValues.map((text: any) => ({button_text_quick_reply: text}))
                : null;

              const buttonUrl = newFormData.buttonUrlTextValues && newFormData.buttonUrlTextValues.length > 0
                ? newFormData.buttonUrlTextValues.map((text: any, index: any) => ({
                  button_text_url: text,
                  button_url: newFormData.buttonUrlValues[index],
                  example_url_values: newFormData.buttonExampleUrlValues[index] || null
                }))
                : null;
              const buttonPhone = newFormData.buttonPhoneTextValues && newFormData.buttonPhoneTextValues.length > 0
                ? newFormData.buttonPhoneTextValues.map((text: any, index: any) => ({
                  button_text_phone_number: text,
                  button_phone_number: newFormData.buttonPhoneValues[index]
                }))
                : null;


              // Check for duplicate button texts
              const allButtonTexts = [
                ...(buttonQuickReply ? buttonQuickReply.map((b: { button_text_quick_reply: any; }) => b.button_text_quick_reply) : []),
                ...(buttonUrl ? buttonUrl.map((b: { button_text_url: any; }) => b.button_text_url) : []),
                ...(buttonPhone ? buttonPhone.map((b: { button_text_phone_number: any; }) => b.button_text_phone_number) : [])
              ];

              const duplicateTexts = new Set();
              const duplicates = allButtonTexts.filter(text => {
                if (duplicateTexts.has(text)) {
                  return true;
                }
                duplicateTexts.add(text);
                return false;
              });

              if (duplicates.length > 0) {
                // Show an error if there are duplicate button texts
                showErrorNotification(`You can't enter the same text for multiple buttons: "${duplicates.join(', ')}"`);
                return;
              }

              let buttonsType: string[] = [];
              if (newFormData.buttonsType === 'QUICK_REPLY') {
                buttonsType = ['QUICK_REPLY'];
              } else if (newFormData.buttonsType === 'None') {
                buttonsType = [];
              } else if (newFormData.actionsType === 'PHONE_NUMBER') {
                buttonsType = ['PHONE_NUMBER'];
              } else if (newFormData.actionsType === 'URL') {
                buttonsType = ['URL'];
              } else if (newFormData.actionsType === 'PHONE_NUMBER_AND_URL') {
                buttonsType = ['URL', 'PHONE_NUMBER'];
              } else if (buttonQuickReply && buttonPhone) {
                buttonsType = ['QUICK_REPLY', 'PHONE_NUMBER'];
              } else if (buttonQuickReply && buttonUrl) {
                buttonsType = ['QUICK_REPLY', 'URL'];
              } else if (values?.buttons?.button_Otp?.length > 0) {
                buttonsType = ['OTP'];
              } else if (values?.buttons?.button_copy_code?.length > 0 && values.buttons.button_copy_code[0] !== "") {
                buttonsType = ['copy_code'];
              }

              const quickReplyLength = buttonQuickReply?.length ?? 0;
              const phoneLength = buttonPhone?.length ?? 0;
              const urlLength = buttonUrl?.length ?? 0;
              const copyCodeLength = values.buttons?.button_copy_code?.length ?? 0;

              if ((quickReplyLength + phoneLength + urlLength + copyCodeLength) > 10) {
                showErrorNotification('Button Count Exceeded. You can\'t have more than 10 button(s) in a message template');
                return;
              }

              if (buttonsType) {
                newFormData.buttons = {
                  buttons_type: buttonsType,
                  button_quick_reply: buttonQuickReply,
                  button_phone_number: buttonPhone,
                  button_url: newFormData.template_type === 'CUSTOM' ? buttonUrl : values.buttons && values.buttons.button_url,
                  button_Otp: values.buttons && values.buttons.button_Otp ? values.buttons.button_Otp : null,
                  button_copy_code: values.buttons && values.buttons.button_copy_code && values.buttons.button_copy_code[0] !== "" ? values.buttons.button_copy_code : null,
                };
              }
            }
            await onSubmitForm(newFormData);
          }
        });
    };

    useEffect(() => {

      if (editItem) {
        form.setFieldsValue({
          waba_id: editItem.waba_id,
          template_name: editItem.template_name,
          template_language: editItem.template_language,
          category: editItem.category,
          header_text: editItem.header_text,
          header_format: editItem.header_format,
          media_url: editItem.media_url,
          example_header_text: editItem.example_header_text,
          body_text: editItem.body_text,
          example_body_text: editItem.example_body_text,
          body_add_security_recommendation: editItem.body_add_security_recommendation,
          footer_text: editItem.footer_text,
          buttons: editItem.buttons,
          code_expiration_minutes: editItem.code_expiration_minutes,
          message_send_ttl_seconds: editItem.message_send_ttl_seconds,
          limited_time_offer: editItem.limited_time_offer,
          carousel: editItem.carousel
        });

        setHeaderType(editItem.header_format);
        setHeaderFormat(editItem.header_format);
        setHeaderText(editItem.header_text);
        setBodyText(editItem.body_text);
        setExampleHeaderValues(editItem.example_header_text || {});
        setExampleBodyValues(editItem.example_body_text || {});
        setFooterText(editItem.footer_text);

        if (editItem.category === 'AUTHENTICATION') {
          setSelectedCategory('AUTHENTICATION');
          setTemplateType('AUTHENTICATION');
          setFormData({"template_type":"AUTHENTICATION", "category":"AUTHENTICATION"});
        }
        else if (editItem.category === 'MARKETING' && editItem.limited_time_offer) {
            setSelectedCategory('MARKETING');
            setTemplateType('LIMITED_TIME_OFFER');
            setFormData({"template_type": "LIMITED_TIME_OFFER", "category": "MARKETING"});
          }
        else if (editItem.category === 'MARKETING' && editItem.carousel) {
            setSelectedCategory('MARKETING');
            setTemplateType('CAROUSEL');
            setFormData({"template_type": "CAROUSEL", "category": "MARKETING"});
          }
        else if (editItem.category === 'UTILITY' && editItem.carousel) {
          setSelectedCategory('UTILITY');
          setTemplateType('CAROUSEL');
          setFormData({"template_type": "CAROUSEL", "category": "UTILITY"});
        }
        else {
            setSelectedCategory(editItem.category);
            setTemplateType('CUSTOM');
            setFormData({"template_type": "CUSTOM", "category": editItem.category});
          }

        if (editItem.header_format === ('IMAGE' || 'VIDEO' || 'LOCATION' || 'DOCUMENT')) {
          setHeaderType('Select Media Type');
          setHeaderFormat(editItem.header_format);
          setHeaderText('');
        } else {
          setHeaderType(editItem.header_format);
          setHeaderFormat('');
          setHeaderText(editItem.header_text);
        }

        if (editItem.buttons?.button_quick_reply) {
          setActionType('');
          setButtonType('QUICK_REPLY');
          const buttonTextEditValue: string[] = editItem.buttons?.button_quick_reply.map((button: any) => button.button_text_quick_reply);
          setButtonTextValues(buttonTextEditValue);
        }
        if (editItem.buttons?.button_copy_code) {
          setActionType('');
          setButtonType('COPY_CODE');
          const buttonTextEditValue: string[] = editItem.buttons?.button_copy_code.map((button: any) => button.button_text_copy_code);
          setButtonCopyCodeTextValues(buttonTextEditValue);
        }
        if (editItem.buttons?.button_phone_number) {
          setActionType('PHONE_NUMBER');
          setButtonType('CALL_TO_ACTION');
          form.setFieldsValue({buttons: {buttons_type: "PHONE_NUMBER"}});
          const buttonTextEditPhoneValues: string[] = editItem.buttons?.button_phone_number.map((button: any) => button.button_text_phone_number);
          setButtonPhoneTextValues(buttonTextEditPhoneValues);
        }
        if (editItem.buttons?.button_url) {
          setActionType('URL');
          setButtonType('CALL_TO_ACTION');
          form.setFieldsValue({buttons: {buttons_type: "URL"}});
          const buttonTextEditUrlValues: string[] = editItem.buttons?.button_url.map((button: any) => button.button_text_url);
          setButtonUrlTextValues(buttonTextEditUrlValues);
        }
        if (editItem.buttons?.button_url && editItem.buttons?.button_phone_number) {
          setActionType('PHONE_NUMBER_AND_URL');
          setButtonType('CALL_TO_ACTION');
          form.setFieldsValue({buttons: {buttons_type: "PHONE_NUMBER_AND_URL"}});
          const buttonTextEditUrlValues: string[] = editItem.buttons?.button_url.map((button: any) => button.button_text_url);
          const buttonTextEditPhoneValues: string[] = editItem.buttons?.button_phone_number.map((button: any) => button.button_text_phone_number);
          setButtonPhoneTextValues(buttonTextEditPhoneValues);
          setButtonUrlTextValues(buttonTextEditUrlValues);
        }
      }
    }, [editItem]);


    const chatContainerStyle: React.CSSProperties = {
      margin: '20px auto',
      padding: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      background: `url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')`, // Replace with your image URL
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      height: 'auto'
    };

    const handleTemplateTypeChange = (value: string) => {
      setTemplateType(value);
      if (selectedCategory !== 'AUTHENTICATION') {
        if (value){
          setIsTemplateTypeChange(true);
        }
        // setSelectedCategory("");
        setButtonType("");
        setActionType("");
        setHeaderText("");
        setHeaderFormat("");
        setBodyText("");
        setFooterText("");
        setButtonTextValues([]);
        setButtonPhoneTextValues([]);
        setButtonUrlTextValues([]);
        setButtonCopyCodeTextValues([]);
        setButtonPhoneValues([]);
        setButtonUrlValues([]);
        setButtonExampleUrlValues([]);
        setExampleBodyValues([]);
        setUploadingFile(false);
        setCardData([]);
        setMainSave("");
      }
    };

    const handleCategoryTypeChange = (value: string) => {
      setSelectedCategory(value);
      if (value){
        setIsCategoryTypeChange(true);
      }
      setButtonType("");
      setActionType("");
      setHeaderText("");
      setHeaderFormat("");
      setBodyText("");
      setFooterText("");
      setButtonTextValues([]);
      setButtonPhoneTextValues([]);
      setButtonUrlTextValues([]);
      setButtonCopyCodeTextValues([]);
      setButtonPhoneValues([]);
      setButtonUrlValues([]);
      setButtonExampleUrlValues([]);
      setExampleBodyValues([]);
      setUploadingFile(false);
      setCardData([]);
      setMainSave("");
    };

    const handleInputDirection = (value: string) => {
      setInputDirection(value);
    };

    const handleButtonTypeChange = (value: string) => {
      setButtonType(value);
    };

    const handleActionTypeChange = (value: any) => {
      setActionType(value);
    };

    const handleHeaderTextChanged = (newHeaderText: string) => {
      setHeaderText(newHeaderText);
    };

    const handleHeaderTypeChanged = (newHeaderType: string) => {
      setHeaderFormat(newHeaderType);
    };

    const handleBodyTextChanged = (newBodyText: string) => {
      setBodyText(newBodyText);
    };

    const handleFooterTextChanged = (newFooterText: string) => {
      setFooterText(newFooterText);
    };

    const handleButtonTextValuesChanged = (newButtonTextValues: any) => {
      setButtonTextValues(newButtonTextValues);
    };


    const handleButtonPhoneTextValues = (newButtonPhoneText: any) => {
      setButtonPhoneTextValues(newButtonPhoneText);
    };


    const handleButtonUrlTextValues = (newButtonUrl: any) => {
      setButtonUrlTextValues(newButtonUrl);
    };

    const handleButtonCopyCodeTextValues = (newButtonCopyCode: any) => {
      setButtonCopyCodeTextValues(newButtonCopyCode);
    };

    const handleButtonPhoneValues = (newButtonValue: any) => {
      setButtonPhoneValues(newButtonValue);
    };

    const handleButtonUrlValues = (newButtonValue: any) => {
      setButtonUrlValues(newButtonValue);
    };

    const handleButtonExampleUrlValues = (newButtonValue: any) => {
      setButtonExampleUrlValues(newButtonValue);
    };

    const handleExampleHeaderTextChanged = (newExampleHeaderValue: any) => {
      setExampleHeaderValues(newExampleHeaderValue);
    };

    const handleExampleBodyTextChanged = (newExampleBodyValue: any) => {
      setExampleBodyValues(newExampleBodyValue);
    };

    const handleUploadFileChanged = (newUploadFile: any) => {
      setUploadingFile(newUploadFile);
    };

    const handleAddNewCardChanged = (newCard: any) => {
      setCardData(newCard);
    };

    const handleOnMainSave = (value: any) => {
      setMainSave(value);
    };


    return (
      <ErrorBoundaryCustom>
        <Modal
          title={editItem ? "Update Whatsapp template" : "Add Whatsapp template"}
          visible={showWhatsappTemplateForm}
          onOk={onOkModel}
          onCancel={onCancelModel}
          footer={null}
          width="75%"

        >
          <Steps current={currentStep - 1}>
            <Step/>
            <Step/>
            <Step/>
          </Steps>
          <br/><br/>
          <Row>
            <Col xs={currentStep !== 1 ? 18 : 23} sm={currentStep !== 1 ? 18 : 23} md={currentStep !== 1 ? 18 : 23}>
              <Form
                name="whatsappMetaTemplateForm"
                layout="horizontal"
                size="small"
                form={form}
                onFinish={onSubmitForm}
              >
                {currentStep === 1 && (
                  <StepOne form={form} editItem={editItem} onTemplateTypeChange={handleTemplateTypeChange}
                           onCategoryChange={handleCategoryTypeChange} onSetInputDirection={handleInputDirection} fetchWabaId={fetchWabaId} loadingTemplate={loadingTemplate} wabaIds={wabaIds}/>
                )}
                {currentStep === 2 && (
                  <StepTwo form={form} editItem={editItem} templateType={templateType}
                           onHeaderTextChanged={handleHeaderTextChanged}
                           onHeaderTypeChanged={handleHeaderTypeChanged} onBodyTextChanged={handleBodyTextChanged}
                           onFooterTextChanged={handleFooterTextChanged}
                           onExampleHeaderTextChanged={handleExampleHeaderTextChanged}
                           onExampleBodyTextChanged={handleExampleBodyTextChanged}
                           onUploadFileChanged={handleUploadFileChanged} isTemplateTypeChange={isTemplateTypeChange}
                           isCategoryTypeChange={isCategoryTypeChange} inputDirection={inputDirection}/>
                )}

                {currentStep === 3 && (
                  <StepThree editItem={editItem} form={form} categoryType={selectedCategory} templateType={templateType}
                             onOtpTypeChange={setOtpButtonType}
                             hasExpiring={hasExpiring}
                             onButtonTypeChange={handleButtonTypeChange} onActionTypeChange={handleActionTypeChange}
                             onButtonTextValues={handleButtonTextValuesChanged}
                             onButtonPhoneTextValues={handleButtonPhoneTextValues}
                             onButtonUrlTextValues={handleButtonUrlTextValues}
                             onButtonCopyCodeTextValues={handleButtonCopyCodeTextValues}
                             onButtonPhoneValues={handleButtonPhoneValues}
                             onButtonUrlValues={handleButtonUrlValues}
                             onButtonExampleUrlValues={handleButtonExampleUrlValues}
                             onAddNewCardChanged={handleAddNewCardChanged} onMainSave={handleOnMainSave}
                             inputDirection={inputDirection}/>)}

                <Form.Item>
                  <div className="mt-4 text-right">
                    {currentStep === 1 && (
                      <Button type="primary" onClick={onNextStep}>
                        Next
                      </Button>
                    )}
                    {currentStep === 2 && (
                      <Col style={{marginTop: templateType === "CAROUSEL" ? "130px" : 'auto'}}>
                        <Button onClick={() => setCurrentStep(1)}
                                style={{marginRight: "12px"}}>Back</Button>
                        <Button type="primary" onClick={() => onNextStep({
                          headerType,
                          headerText,
                          exampleHeaderValues,
                          headerFormat,
                          bodyText,
                          exampleBodyValues,
                          footerText, cardData
                        })}>
                          Next
                        </Button>
                      </Col>
                    )}
                    {currentStep === 3 && (
                      <Col style={{marginTop: (templateType === "CAROUSEL" || templateType === "LIMITED_TIME_OFFER") ? "auto" : '260px'}}>
                        <Button onClick={() => setCurrentStep(2)}
                                style={{marginRight: "12px"}} disabled={uploadingFile}>Back</Button>
                        <Button type="primary" onClick={() => onNextStep({
                          otpButtonType,
                          buttonType,
                          buttonTextValues,
                          actionType,
                          buttonPhoneTextValues,
                          buttonUrlTextValues,
                          fieldsAction,
                          cardData,
                          buttonUrlValues,
                          buttonCopyCodeTextValues,
                          buttonPhoneValues,
                          buttonExampleUrlValues
                        })} loading={loading} disabled={uploadingFile}>
                          Save
                        </Button>
                      </Col>
                    )}
                  </div>
                </Form.Item>
              </Form>
            </Col>
            <Col xs={1} sm={1} md={1}/>
            {currentStep !== 1 && (
              <Col xs={5} sm={5} md={5} style={chatContainerStyle}>
                <ChatPreviewPage headerText={headerText} bodyText={bodyText} footerText={footerText}
                                 selectedMediaType={headerFormat} buttonType={buttonType}
                                 actionType={actionType} actionButtonsQuickReply={buttonTextValues}
                                 actionButtonsPhoneNumber={buttonPhoneTextValues}
                                 actionButtonsUrl={buttonUrlTextValues}
                                 buttonCopyCodeTextValues={buttonCopyCodeTextValues}
                                 isCarousel={false}
                                inputDirection={inputDirection} templateType={templateType}/>
              </Col>)}
          </Row>

        </Modal>
      </ErrorBoundaryCustom>

    );
  }
;

WhatsappMetaTemplateForm.defaultProps = {
  onSave: undefined,
  onClose: undefined,
  editItem: undefined,
  fetchWabaId: undefined,
  loadingTemplate: undefined,
  wabaIds: undefined,
};

export default WhatsappMetaTemplateForm;
