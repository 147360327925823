import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Modal, Row, Select, Switch} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import languageOptions from "../language";
// import {listWhatsappMetaWabaIdService} from "../../../../../services/dashboard/WhatsappService";
import {WhatsappMetaWabaLists, WhatsappTemplateDetails} from "../../../../../models";


export interface StepOneProps {
  form?: any;
  editItem?: WhatsappTemplateDetails;
  onTemplateTypeChange?: any;
  onCategoryChange?: any;
  onSetInputDirection?: any;
  fetchWabaId?: () => void;
  loadingTemplate?: boolean;
  wabaIds?: WhatsappMetaWabaLists;
}

const StepOne = (props: StepOneProps) => {

  const {editItem, onTemplateTypeChange, onCategoryChange, form, onSetInputDirection, fetchWabaId, loadingTemplate, wabaIds} = props;
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  // const [wabaIds, setWabaIds] = useState([{waba_id: '', name: ''}]);
  // const [loading, setLoading] = useState(false);
  const [preventToggle, setPreventToggle] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [inputDirection, setInputDirection] = useState('ltr');


  const handleCategoryChange = (value: any) => {
    setSelectedCategory(value);
    if (onCategoryChange) {
      onCategoryChange(value);
    }
  };

  const handleTemplateTypeChange = (value: string) => {
    if (onTemplateTypeChange) {
      onTemplateTypeChange(value);
    }
  };

  // const renderTemplateTypeOptions = () => {
  //   if (selectedCategory === "AUTHENTICATION") {
  //     handleTemplateTypeChange('AUTHENTICATION');
  //     return null;
  //   }
  //
  //   return null;
  // };

  const handleLanguageChange = (value: string[]) => {
    const rtlLanguages = ["ar", "he", "fa", "ur"]; // List of RTL language codes
    const isRtl = value.some(lang => rtlLanguages.includes(lang));
    setInputDirection(isRtl ? 'rtl' : 'ltr');
    onSetInputDirection(isRtl ? 'rtl' : 'ltr');
  };

  useEffect(() => {

    const fetchData = async () => {
      if (editItem) {
        if (editItem.category === 'AUTHENTICATION') {
          setSelectedCategory('AUTHENTICATION');
          await onCategoryChange('AUTHENTICATION');
          form.setFieldsValue({
            template_type: 'AUTHENTICATION',
          });
        }
        if (editItem.category === 'MARKETING' && editItem.limited_time_offer) {
          setSelectedCategory('MARKETING');
          form.setFieldsValue({
            template_type: 'LIMITED_TIME_OFFER',
          });
        } else if (editItem.category === 'MARKETING' && editItem.carousel) {
          setSelectedCategory('MARKETING');
          form.setFieldsValue({
            template_type: 'CAROUSEL',
          });
        } else if (editItem.category === 'MARKETING' && !editItem.carousel && !editItem.limited_time_offer) {
          setSelectedCategory('MARKETING');
          form.setFieldsValue({
            template_type: 'CUSTOM',
          });
        }
      }
    };
    if (form.getFieldValue('category')) {
      setSelectedCategory(form.getFieldValue('category'));
    }

    // if (form.getFieldValue('template_language')) {
    //   const rtlLanguages = ["ar", "he", "fa", "ur"]; // List of RTL language codes
    //   const isRtl = (form.getFieldValue('template_language')).some((lang: string) => rtlLanguages.includes(lang));
    //   setInputDirection(isRtl ? 'rtl' : 'ltr');
    // }
    if (fetchWabaId) {
      fetchWabaId();
    }
    fetchData();
  }, [editItem, inputDirection]);


  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item label="WABA ID" name='waba_id' rules={[
            {
              required: true,
              message: 'Please select your WABA ID!',
            },
          ]} labelCol={{span: 5}}>
            <Select loading={loadingTemplate} placeholder="Choose your WABA ID" disabled={!!editItem}>
              {wabaIds?.length === 0 ? (
                <Select.Option value="addWabaId"><a href="/whatsapp-meta/getting-started">Onboard your whatsapp
                  number</a></Select.Option>
              ) : (
                wabaIds?.map((wabaId, index) => (
                  <Select.Option key={wabaId.waba_id} value={wabaId.waba_id}>
                    {wabaId.name}
                  </Select.Option>
                ))
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Name"
            name="template_name"
            labelCol={{span: 5}}
            rules={[
              {
                required: true,
                message: 'Please enter template name',
              },
              {
                validator: (_, value) => {
                  if (value && value !== value.toLowerCase()) {
                    return Promise.reject(new Error('Only lowercase letters are supported.'));
                  }
                  return Promise.resolve();
                },
              },
              {
                pattern: /^[a-z0-9_]+$/,
                message: 'Template name should contain lowercase alphabets, underscores, and numbers only',
              },
              {
                max: 512,
                message: 'template name cannot exceed 512 characters',
              },
            ]}
          >
            <Input disabled={!!editItem}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Language"
            name="template_language"
            labelCol={{span: 5}}

            rules={[
              {
                required: true,
                message: 'Please select a Language',
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select a Language"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              disabled={!!editItem}
              onChange={handleLanguageChange} // Handle language change
            >
              {Object.keys(languageOptions).map(code => (
                <Select.Option key={code} value={code}>
                  {languageOptions[code]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <br/>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24}>
          <h4>
            Category Information
          </h4>

        </Col>
        <br/><br/>

        <Col xs={24} sm={12} md={12}>
          <Form.Item
            label="Category"
            name="category"
            labelCol={{span: 5}}
            rules={[
              {
                required: true,
                message: 'Please select Template Category',
              },
            ]}
          >
            <Select
              placeholder="Select a Category"
              disabled={!!editItem}
              onChange={(value) => {
                handleCategoryChange(value);
                // If the selected category is AUTHENTICATION, directly call handleTemplateTypeChange with "AUTHENTICATION"
                if (value === 'AUTHENTICATION') {
                  handleTemplateTypeChange("AUTHENTICATION");
                } else {
                  // Otherwise, clear the template type selection if it's not AUTHENTICATION
                  form.setFieldsValue({ template_type: undefined });
                }
              }}
            >
              <Select.Option value="AUTHENTICATION">Authentication</Select.Option>
              <Select.Option value="MARKETING">Marketing</Select.Option>
              <Select.Option value="UTILITY">Utility</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={10} md={10} className="ml-4">
          <Form.Item
            label={
              <span>
        Allow Category <br/>  Change
        <InfoCircleOutlined
          style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
          onClick={(event) => {
            event.stopPropagation();
            setPreventToggle(true);
            Modal.info({
              title: "Allow Category Change",
              content: (
                <>
                  <div>
                    You can include the <b>Allow category change</b> to <b>true</b> to categorize your
                    template based on its content. This can prevent your template status from
                    immediately being set to REJECTED.
                  </div>
                  <br/>
                  <div style={{opacity: '0.5'}}>
                    If there is a mismatch between the designated category and the category we
                    determine based on your template content, the template will be created but its
                    status will be set to REJECTED
                  </div>
                </>
              ),
              onOk: () => setPreventToggle(false)
            });
          }}
        />
      </span>
            }
            name="allow_category_change"
            labelCol={{span: 5}}
            labelAlign='left'
            valuePropName="checked"
            getValueProps={(value) => ({checked: value})}
            getValueFromEvent={(checked) => {
              if (preventToggle) {
                return switchChecked; // Return the current switch state if preventToggle is true
              }
              return checked; // Otherwise, return the new switch state
            }}
          >
            <Switch
              disabled={!!editItem}
              checked={switchChecked} // Set the checked state of the switch
              onChange={(checked) => setSwitchChecked(checked)} // Update the switch state
            />
          </Form.Item>
        </Col>
        {selectedCategory && selectedCategory !== "AUTHENTICATION" && (
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label="Template Type"
              name="template_type"
              labelCol={{span: 5}}
              rules={[
                {
                  required: true,
                  message: 'Please select a Template Type',
                },
              ]}
            >
              <Select onChange={handleTemplateTypeChange} placeholder="Select a Template Type" disabled={!!editItem}>
                {selectedCategory === "MARKETING" && (
                  <>
                    <Select.Option value="CUSTOM">Custom</Select.Option>
                    <Select.Option value="CAROUSEL">Carousel</Select.Option>
                    <Select.Option value="LIMITED_TIME_OFFER">Limited Time Offer</Select.Option>
                  </>
                )}
                {selectedCategory === "UTILITY" && (
                  <>
                    <Select.Option value="CUSTOM">Custom</Select.Option>
                    <Select.Option value="CAROUSEL">Carousel</Select.Option>
                  </>
                )}
              </Select>
            </Form.Item>
          </Col>
        )}


      </Row>
    </>
  );
};

StepOne.defaultProps = {
  form: undefined,
  editItem: undefined,
  onTemplateTypeChange: undefined,
  onCategoryChange: undefined,
  onSetInputDirection: undefined,
  fetchWabaId: undefined,
  loadingTemplate: undefined,
  wabaIds: undefined,
};


export default StepOne;
