import React, {useState} from 'react';
import {
  Button,
  Col, Dropdown,
  Input, Row, Select, Form, notification, Tooltip, Upload, message as antdMessage
} from "antd";
import {CloudUploadOutlined, DeleteOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {uploadMediaFileService} from "../../../../services/dashboard/WhatsappService";

export interface campaignCarouselCardProps {
  templateCardComponent: any;
  onFinishCard: (card: any) => void;
  mergeCards: ()=> void;
  onClickPrev: any;
  activeKey:number;
  lastcard:boolean;
  inputDirection?: any;
  selectedOriginator: any;
}

const campaignCarouselCard = (props: campaignCarouselCardProps) => {
  const {templateCardComponent, onFinishCard, onClickPrev, activeKey, lastcard, mergeCards, inputDirection, selectedOriginator} = props;
  const [bodyParameters, setBodyParameters] = useState<{ [key: string]: string }>({});
  const [mediaUrl, setMediaUrl] = useState<string>();
  const [buttonPayload, setButtonPayload] = useState<string>();
  const [buttonReply1, setButtonReply1] = useState<string>();
  const [buttonReply2, setButtonReply2] = useState<string>();
  const [personalizeMenuVisibility, setPersonalizeMenuVisibility] = useState({});
  const [disableDoneButton, setDisableDoneButton] = useState(false);
  const [uploadingCarouselFile, setUploadingCarouselFile] = useState(false);
  const [fileList, setFileList] = useState<any>([]);

  const PERSONALIZATION_FIELDS = [
    {text: 'First Name', value: '{first_name}'},
    {text: 'Last Name', value: '{last_name}'},
    {text: 'Email', value: '{email}'},
    {text: 'Number', value: '{number}'},
    {text: 'Var 1', value: '{var1}'},
    {text: 'Var 2', value: '{var2}'},
    {text: 'Var 3', value: '{var3}'},
    {text: 'Var 4', value: '{var4}'},
    {text: 'Var 5', value: '{var5}'},
    {text: 'Country', value: '{country}'},
    {text: 'Blacklist', value: '{blacklist}'}];

  // @ts-ignore
  const isPersonalizeMenuVisible = (paramKey: any) => personalizeMenuVisibility[paramKey] || false;

  const showErrorNotification = (messageText: string) => {
    const notificationParam = {
      message: messageText,
      description: ''
    };
    notification.error(notificationParam);
  };

  const showSuccessNotification = (messageText: string) => {
    const notificationParam = {
      message: messageText,
      description: ''
    };
    notification.success(notificationParam);
  };

  const formatBodyParam = (params: any) =>{
    const bodyArray: any = [];
    Object.keys(params).map((key, index) => (
      bodyArray.push({type: "text", text: bodyParameters[key]})
    ));
    return bodyArray;
  };
  const onSubmitCardForm = () => {
    const body = templateCardComponent?.example_body_text==={}?null:{type: "body", parameters: formatBodyParam(bodyParameters)};
    // let buttonError = false;
    let buttonUrl = null;
    let buttonRe1 = null;
    let buttonRe2 = null;
    let parameters: any;
    if (templateCardComponent?.header_format==="IMAGE"){
      parameters = [{
        type: "image",
        image: {
          "id": mediaUrl
        }
      }];
    }
    else{
      parameters = [{
        type: "video",
        video: {
          "id": mediaUrl
        }
      }];
    }
    if (templateCardComponent?.buttons?.button_quick_reply){
      if (templateCardComponent?.buttons?.button_quick_reply.length>=1){
        if (buttonReply1){
          // @ts-ignore
          buttonRe1 = {type: "button", sub_type: "quick_reply", index: "0", parameters: [{"type": "payload", "payload": buttonReply1}]};
        }
      }
      if (templateCardComponent?.buttons?.button_quick_reply.length>=2){
        if (buttonReply2){
          // @ts-ignore
          buttonRe2 = {type: "button", sub_type: "quick_reply", index: "1", parameters: [{"type": "payload", "payload": buttonReply2}]};
        }
      }
    }
    if (templateCardComponent?.buttons?.button_url){
      if (templateCardComponent?.buttons?.button_url.length>0){
        if (templateCardComponent?.buttons?.button_url[0].example_url_values){
          const buttonIndex = templateCardComponent?.buttons?.button_quick_reply?.length? String(templateCardComponent?.buttons?.button_quick_reply?.length): "0";
          // @ts-ignore
          buttonUrl = {type: "button", sub_type: "url", index: buttonIndex, parameters: [{"type": "payload", "payload": buttonPayload}]};
        }
        // else{
        //   buttonError=true;
        // }
      }
    }
    if(!mediaUrl){
      showErrorNotification("Please upload valid media file!");
    }
    else if (Object.keys(templateCardComponent?.example_body_text).length !== Object.keys(bodyParameters).length){
      showErrorNotification("Please enter all body parameters");
    }
    // else if (buttonError){
    //   showErrorNotification("Please enter url text");
    // }
    else {
      const carouselCard = {
        card_index: activeKey,
        components: [
          {
            type: "header",
            parameters
          },
          body]
      };
      if (buttonUrl){
        carouselCard.components.push(buttonUrl);
      }
      if (buttonRe1){
        carouselCard.components.push(buttonRe1);
      }
      if (buttonRe2){
        carouselCard.components.push(buttonRe2);
      }
      onFinishCard?.(carouselCard);
      if (lastcard){
        showSuccessNotification("Card details added successfully");
        setDisableDoneButton(true);
        mergeCards();
      }
    }
  };

  const handleBodyParameterChange = (key: any, value: any) => {
    setBodyParameters({
      ...bodyParameters,
      [key]: value,
    });
  };

  const handlePersonalizeMenuVisibilityChange = (paramKey: any, visible: any) => {
    setPersonalizeMenuVisibility((prevVisibility) => ({...prevVisibility, [paramKey]: visible}));
  };

  const handlePersonalizeChange = (paramKey: any, value: any) => {
    const newValue = `{${value}}`;
    const updatedValue = bodyParameters[paramKey] ? `${bodyParameters[paramKey]} ${newValue}` : newValue;
    const updatedBodyParameters = {...bodyParameters, [paramKey]: updatedValue};
    setBodyParameters(updatedBodyParameters);
    handlePersonalizeMenuVisibilityChange(paramKey, false);
  };

  const createPersonalizeMenu = (paramKey: any) => (
    <div className="nav-profile nav-dropdown p-3" style={{height: 250, width: 220}}>
      <h5 className="mb-2">Personalize</h5>
      <Select
        showSearch
        style={{width: '100%'}}
        placeholder="Search"
        optionFilterProp="children"
        onChange={(value) => handlePersonalizeChange(paramKey, value)}
        filterOption={(input: any, option: any) =>
          option?.key.toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
          option.title.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {PERSONALIZATION_FIELDS.map((e) => (
          <Select.Option key={e.value} value={e.value} title={e.text}>
            {e.text}
          </Select.Option>
        ))}
      </Select>
    </div>
  );

  const handlePrevButtonClick = () => {
    setDisableDoneButton(false); // Resetting the state to enable the "Done" button
    onClickPrev();
  };

  const fileUploadCustomRequest = (options: any) => {
    const fileType = options.file.type;
    const fileSize = options.file.size / 1024/ 1024;
    const image = ["image/jpeg", "image/png", "image/jpg"];
    const video = ["image/jpg", "video/mp4"];
    if ((image.includes(fileType) && fileSize > 5) || (video.includes(fileType) && fileSize > 16)) {
      showErrorNotification("File Too Large: The file you uploaded is too large.");
      setUploadingCarouselFile(false);
      setFileList([]);
      setMediaUrl("");
    }
    else {
      setUploadingCarouselFile(true);
      const data = new FormData();
      data.append('file', options.file);
      uploadMediaFileService(selectedOriginator, options.file)
        .then(response => {
            if (response.id !== "None") {
              options.onSuccess(response, options.file);
            } else {
              antdMessage.error(`${options.file} file type is not supported.`);
            }
          }
        ).catch(error => {
        showErrorNotification(error.message);
        setUploadingCarouselFile(false);
        setFileList([]);
        setMediaUrl("");
      })
        .finally(() => setUploadingCarouselFile(false));
    }
  };

  const onChangeFile = (info: any) => {
    const {status, response} = info.file;
    setFileList(info.fileList);
    if (status === "done") {
      antdMessage.success(`${info.file.name} file uploaded successfully.`);
      setMediaUrl(response.id);
    } else if (status === "error") {
      setFileList([]);
      setMediaUrl("");
      antdMessage.error(`${info.file.name} file upload failed.`);
    } else if (status === "removed") {
      setFileList([]);
      setMediaUrl("");
    }
  };

  const renderUploadFormItem = (label: any, accept: any) => (
    <Form.Item
      label={
        <span>
        {label}
          <Tooltip className="mx-1"
                   title="To help us understand what kind of message you want to send, you have the option to provide specific content examples for your template. Make sure not to include any actual user or customer information, and provide only sample content in your examples.">
          <InfoCircleOutlined/>
        </Tooltip>
      </span>
      }
      labelCol={{span: 7}}
      style={{flexDirection: 'row'}}

      name={['content', 'media', 'media_url']}
      rules={[{required: true, message: 'Please upload a file'}]}
    >
      <Upload
        name="file"
        accept={accept}
        maxCount={1}
        fileList={fileList}
        customRequest={fileUploadCustomRequest}
        onChange={onChangeFile}
        showUploadList={{showRemoveIcon: true, removeIcon: <DeleteOutlined/>}}
      >
        <Button type="default" className="bg-gray-lightest mx-2 px-5 w-100">
          <CloudUploadOutlined/> Upload
        </Button>
      </Upload>
    </Form.Item>
  );

  return (
    <Form
      layout="vertical"
      size="small">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
            {templateCardComponent && (
              <div>
                <Col xs={24} sm={24} md={24} className='mt-5'>
                  {templateCardComponent.header_format === 'IMAGE' && renderUploadFormItem('Upload Image Header', '.jpeg, .jpg, .png')}
                  {templateCardComponent.header_format === 'VIDEO' && renderUploadFormItem('Upload Video Header', '.3gp, .mp4')}
                  {templateCardComponent.header_format === 'DOCUMENT' && renderUploadFormItem('Upload Document Header', '.txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf')}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  {templateCardComponent.body_text && Object.keys(templateCardComponent.example_body_text).length > 0 && (
                    <Form.Item
                      label="Body Parameters"
                      labelCol={{span: 7}}
                      required
                    >
                      <div>
                        {Object.keys(templateCardComponent.example_body_text).map((key, index) => (
                          <div key={key} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                            <span style={{marginRight: '10px'}}>{`${key}:`}</span>
                            <Input
                              value={bodyParameters[key] || ''}
                              onChange={(e) => handleBodyParameterChange(key, e.target.value)}
                              dir={inputDirection}
                            />
                            <div className="text-right">
                              <Dropdown
                                overlay={createPersonalizeMenu(key)}
                                onVisibleChange={(visible) => handlePersonalizeMenuVisibilityChange(key, visible)}
                                visible={isPersonalizeMenuVisible(key)}
                                placement="topRight"
                              >
                                <Button type="link">
                                  Personalize
                                </Button>
                              </Dropdown>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Form.Item>
                  )}
                </Col>
                {templateCardComponent.buttons ? (
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {(templateCardComponent.buttons?.button_quick_reply?.length > 0) && (
                        <Form.Item /* eslint-disable react/no-array-index-key */>
                          {templateCardComponent.buttons?.button_quick_reply.map((button: { button_text_quick_reply: any; }, index: React.Key | null | undefined) => (
                            <div key={index}>
                              {(button.button_text_quick_reply && index===0) && (
                                <Form.Item label="Quick Reply 1"
                                           labelCol={{span: 7}}>
                                  <Input value={buttonReply1} onChange={(e)=>setButtonReply1(e.target.value)}
                                         dir={inputDirection}/>
                                </Form.Item>
                              )}
                              {(button.button_text_quick_reply && index===1) && (
                                <Form.Item label="Quick Reply 2"
                                           labelCol={{span: 7}}>
                                  <Input value={buttonReply2} onChange={(e)=>setButtonReply2(e.target.value)} dir={inputDirection}/>
                                </Form.Item>
                              )}
                            </div>
                          ))}
                        </Form.Item>
                      )}
                    </Col>)
                  : []}
                {templateCardComponent.buttons ? (
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {(templateCardComponent.buttons?.button_url?.length > 0) && (
                        <Form.Item /* eslint-disable react/no-array-index-key */>
                          {templateCardComponent.buttons?.button_url.map((button: { example_url_values: any; }, index: React.Key | null | undefined) => (
                            <div key={index}>
                              {button.example_url_values && (
                                <Form.Item label="Dynamic URL Variable"
                                           required
                                           labelCol={{span: 10}}>
                                  <Input value={buttonPayload} onChange={(e)=>setButtonPayload(e.target.value)}/>
                                </Form.Item>
                              )}
                            </div>
                          ))}
                        </Form.Item>
                      )}
                    </Col>)
                  : []}
                <Col xs={24} sm={24} md={24}>
                  <div className="mt-4 text-right">
                    {activeKey>0&& <Button htmlType="button" onClick={handlePrevButtonClick}>
                      Prev
                    </Button>}
                    {lastcard ? <Button type="primary" className="ml-2" onClick={onSubmitCardForm} disabled={disableDoneButton}>
                      Done
                    </Button>:
                      <Button type="primary" className="ml-2" onClick={onSubmitCardForm} disabled={uploadingCarouselFile}>
                        Next
                      </Button>}
                  </div>
                </Col>
              </div>
            )}
        </Col>
      </Row>
    </Form>
  );
};

campaignCarouselCard.defaultProps = {
  // onSaveCard: undefined,
};


export default campaignCarouselCard;
