import {
  WhatsappCampaignCreateRequest,
  WhatsappCampaignUpdateRequest,
  WhatsappCampaignManageGetRequest,
  WhatsappCampaignProcessingGetRequest,
  VerifyRequestSummaryGetRequest
} from 'models/requests';

import {
  // campaignReportSearch,
  cancelWhatsappCampaign,
  createWhatsappCampaign,
  editWhatsappCampaign,
  getAllWhatsappCampaigns,
  getWhatsappCampaign,
  getProcessingWhatsappCampaign,
  getVerifyRequestSummary,
  manageWhatsappCampaignSearch,
  getWhatsappCampaignMsgLog, whatsappCampaignReportSearch
} from "api/dashboard/WhatsappCampaignApi";

const getAllWhatsappCampaignsService = async (data: WhatsappCampaignManageGetRequest) => getAllWhatsappCampaigns(data);
const createWhatsappCampaignService = async (data: WhatsappCampaignCreateRequest) => createWhatsappCampaign(data);
const editWhatsappCampaignService = async (data: WhatsappCampaignUpdateRequest, id: string) => editWhatsappCampaign(data, id);
const getWhatsappCampaignVerifyRequestSummaryService = async (data: VerifyRequestSummaryGetRequest) => getVerifyRequestSummary(data);
const stopWhatsappCampaignService = async (id: string) => cancelWhatsappCampaign(id);
const getWhatsappCampaignService = async (id: string) => getWhatsappCampaign(id);
const getProcessingWhatsappCampaignService = async (data: WhatsappCampaignProcessingGetRequest) => getProcessingWhatsappCampaign(data);

const manageWhatsappCampaignSearchService = async (key: string) => manageWhatsappCampaignSearch(key);
const whatsappCampaignReportSearchService = async (key: string) => whatsappCampaignReportSearch(key);
const getWhatsappCampaignMsgLogService = async (id: string) => getWhatsappCampaignMsgLog(id);
export {
  getAllWhatsappCampaignsService,
  createWhatsappCampaignService,
  editWhatsappCampaignService,
  getWhatsappCampaignVerifyRequestSummaryService,
  stopWhatsappCampaignService,
  getWhatsappCampaignService,
  getProcessingWhatsappCampaignService,
  manageWhatsappCampaignSearchService,
  whatsappCampaignReportSearchService,
  getWhatsappCampaignMsgLogService
};
