import React, {useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message as antdMessage,
  Modal, Row,
  Select,
  Tooltip,
  Upload
} from "antd";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  SaveOutlined
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {uploadResumableFileService} from "../../../../../../services/dashboard/WhatsappService";
import {WhatsappTemplateDetails} from "../../../../../../models";
import {showErrorNotification} from "../../../../../../utils/helper";


export interface StepTwoCarouselComponentProps {
  form: any;
  onCardDataChange: any;
  onSaveCard: any;
  inputDirection?: any;
  editItem?: WhatsappTemplateDetails;
}

const StepTwoCarouselComponent = (props: StepTwoCarouselComponentProps) => {

  const {form, onCardDataChange, onSaveCard, editItem, inputDirection} = props;
  const [headerType, setHeaderType] = useState<string>('');
  const [headerFormat, setHeaderFormat] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [exampleBodyValues, setExampleBodyValues] = useState<any>({});
  const [uploadingFile, setUploadingFile] = useState(false);
  const [mediaUrl, setMediaUrl] = useState<any>({});
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [errorMessage] = useState('');
  const [buttonsType, setButtonsType] = useState('');
  const [actionsType, setActionsType] = useState('');
  const [buttonTextValues, setButtonTextValues] = useState<string[]>([]);
  const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
  const [fieldsAction, setFieldsAction] = useState([{key: 'initial_key'}]);
  const [buttonPhoneValues, setButtonPhoneValues] = useState<string[]>([]);
  const [buttonUrlValues, setButtonUrlValues] = useState<string[]>([]);
  const [buttonExampleUrlValues, setButtonExampleUrlValues] = useState<string[]>([]);
  const [buttonPhoneTextValues, setButtonPhoneTextValues] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any>([]);


  const handleButtonTypeChange = (value: string) => {
    setButtonsType(value);
  };

  const handleActionTypeChange = (value: any) => {
    setActionsType(value);
  };

  const handleButtonTextChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonTextValues];
    newButtonTextValues[index] = value;
    setButtonTextValues(newButtonTextValues);
  };

  const handleButtonPhoneChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonPhoneValues];
    newButtonTextValues[index] = value;
    setButtonPhoneValues(newButtonTextValues);
  };

  const handleButtonUrlChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonUrlValues];
    newButtonTextValues[index] = value;
    setButtonUrlValues(newButtonTextValues);
  };

  const handleButtonExampleUrlChange = (index: any, value: any) => {
    const newButtonTextValues = [...buttonExampleUrlValues];
    newButtonTextValues[index] = value;
    setButtonExampleUrlValues(newButtonTextValues);
  };

  const handleButtonPhoneTextChange = (index: any, value: any) => {
    const newButtonPhoneTextValues = [...buttonPhoneTextValues];
    newButtonPhoneTextValues[index] = value;
    setButtonPhoneTextValues(newButtonPhoneTextValues);
  };

  const handleButtonUrlTextChange = (index: any, value: any) => {
    const newButtonUrlTextValues = [...buttonUrlTextValues];
    newButtonUrlTextValues[index] = value;
    setButtonUrlTextValues(newButtonUrlTextValues);
  };


  const addField = () => {
    const newField = {key: `new_key_${fieldsAction.length}`};
    setFieldsAction([...fieldsAction, newField]);
  };

  const removeField = (index: number) => {
    const newFields = [...fieldsAction];
    newFields.splice(index, 1);
    setFieldsAction(newFields);

    const newPhoneTextValues = [...buttonPhoneTextValues];
    newPhoneTextValues.splice(index, 1);
    setButtonPhoneTextValues(newPhoneTextValues);

    const newUrlTextValues = [...buttonUrlTextValues];
    newUrlTextValues.splice(index, 1);
    setButtonUrlTextValues(newUrlTextValues);
  };

  const addCard = async () => {
    try {
      await form.validateFields();

      const buttonQuickReply = buttonTextValues.length > 0
        ? buttonTextValues.map(text => ({button_text_quick_reply: text}))
        : null;

      const buttonUrl = buttonUrlTextValues.length > 0
        ? buttonUrlTextValues.map((text, index) => ({
          button_text_url: text,
          button_url: buttonUrlValues[index],
          example_url_values: buttonExampleUrlValues[index] || null
        }))
        : null;
      let buttonType: string[] = [];
      if (buttonsType === 'QUICK_REPLY') {
        buttonType = ['QUICK_REPLY'];
      } else if (buttonsType === 'None') {
        buttonType = [];
      } else if (actionsType === 'PHONE_NUMBER') {
        buttonType = ['PHONE_NUMBER'];
      } else if (actionsType === 'URL') {
        buttonType = ['URL'];
      } else if (actionsType === 'PHONE_NUMBER_AND_URL') {
        buttonType = ['URL', 'PHONE_NUMBER'];
      }

      const newCardData = {
        header_format: headerFormat || null,
        media_url: mediaUrl?.header_handle || null,
        body_text: bodyText || null,
        example_body_text: {...exampleBodyValues},
        buttons: buttonsType ? {
          buttons_type: buttonType,
          button_quick_reply: buttonQuickReply,
          button_phone_number: buttonPhoneTextValues.length > 0 ? [{
            button_text_phone_number: buttonPhoneTextValues[0],
            button_phone_number: buttonPhoneValues[0]
          }] : null,
          button_url: buttonUrl
        } : null
      };

      Modal.confirm({
        title: 'Save Card',
        content: 'Once the card is saved, it cannot be edited. Are you sure you want to save?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          setIsSaved(true);
          onSaveCard(isSaved);
          onCardDataChange(newCardData);
        },
        onCancel: () => {
          // Handle cancel button click if needed
          setIsSaved(false); // Reset isCardSaved if the user cancels the save
        },
      });

      // setIsSaved(true);
      // // showSuccessNotification('Card saved successfully!');
      // onSaveCard(isSaved);
      // onCardDataChange(newCardData);
    } catch (error) {
      // Handle validation errors, you can display an error message or take any other action
      // console.error('Validation failed:', error);
    }


  };

  const handleHeaderTypeChange = (value: any) => {
    setHeaderType(value);
    if (value === 'TEXT') {
      setHeaderFormat('');
    }
  };

  const fileUploadCustomRequest = (options: any) => {
    const isMedia = options.file.type;
    if (isMedia === "video/3gp" || isMedia === "image/jpeg" || isMedia === "image/jpg" || isMedia === "image/png" || isMedia === "video/mp4") {
      setUploadingFile(true);
      const data = new FormData();
      data.append('file', options.file);
      uploadResumableFileService(options.file)
        .then(response => {
            if (response.header_handle !== "None") {
              setMediaUrl(response);
              options.onSuccess(response, options.file);
            } else {
              antdMessage.error(`${options.file} file type is not supported.`);
            }
          }
        ).catch(error => {
        showErrorNotification(error.message);
        setUploadingFile(false);
        setFileList([]);
        setMediaUrl('');
        form.setFieldsValue({card_media_url: ''});
      })
        .finally(() => setUploadingFile(false));
    } else {
      antdMessage.error(`${options.file} file type is not supported.`);
    }
  };

  const onChangeFile = (info: any) => {
    const {status, response} = info.file;
    setFileList(info.fileList);
    if (status === "done") {
      antdMessage.success(`${info.file.name} file uploaded successfully.`);
      form.setFieldsValue({card_media_url: response.header_handle});
    } else if (status === "error") {
      form.setFieldsValue({card_media_url: ''});
      setFileList([]);
      setMediaUrl('');
      antdMessage.error(`${info.file.name} file upload failed.`);
    } else if (status === "removed") {
      setFileList([]);
      setMediaUrl('');
      form.setFieldsValue({card_media_url: ''});
    }
  };

  const handleAddVariableToBody = () => {
    const bodyVariables = bodyText.match(/{{(\d+)}}/g);
    const lastVariableCount = bodyVariables
      ? Math.max(...bodyVariables.map((match: any) => parseInt(match.match(/{{(\d+)}}/)[1], 10)))
      : 0;
    const newBodyVariableIndex = (lastVariableCount + 1).toString();

    setBodyText((prevTextBody) => `${prevTextBody}{{${newBodyVariableIndex}}}`);

    setExampleBodyValues((prevValues: any) => ({
      ...prevValues,
      [newBodyVariableIndex]: '',
    }));
  };

  useEffect(() => {
    if (editItem && form) {
      if (editItem.carousel.components.length > 0) {
        editItem.carousel.components.forEach(editCarousel => {
          setHeaderType(editCarousel.header_format ? 'Select Media Type' : '');
          setBodyText(editCarousel.body_text);
          setExampleBodyValues(editCarousel.example_body_text);
          form.setFieldsValue({
            card_header_format: editCarousel.header_format || '',
            card_body_text: editCarousel.body_text || '',
            card_example_body_text: editCarousel.example_body_text || '',
            buttons: editCarousel.buttons,
          });

          if (editCarousel.buttons?.button_quick_reply) {
            setButtonsType('QUICK_REPLY');
            const buttonTextEditValue: string[] = editCarousel.buttons?.button_quick_reply.map((button: any) => button.button_text_quick_reply);
            setButtonTextValues(buttonTextEditValue);
          }
          if (editCarousel.buttons?.button_phone_number) {
            setActionsType('PHONE_NUMBER');
            setButtonsType('CALL_TO_ACTION');
            const buttonTextEditPhoneValues: string[] = editCarousel.buttons?.button_phone_number.map((button: any) => button.button_text_phone_number);
            const buttonEditPhoneValues: string[] = editCarousel.buttons?.button_phone_number.map((button: any) => button.button_phone_number);
            setButtonPhoneTextValues(buttonTextEditPhoneValues);
            setButtonPhoneValues(buttonEditPhoneValues);
          }
          if (editCarousel.buttons?.button_url) {
            setActionsType('URL');
            setButtonsType('CALL_TO_ACTION');
            const buttonTextEditUrlValues: string[] = editCarousel.buttons?.button_url.map((button: any) => button.button_text_url);
            setButtonUrlTextValues(buttonTextEditUrlValues);
          }
          if (editCarousel.buttons?.button_url && editCarousel.buttons?.button_phone_number) {
            setActionsType('PHONE_NUMBER_AND_URL');
            setButtonsType('CALL_TO_ACTION');
            const buttonTextEditUrlValues: string[] = editCarousel.buttons?.button_url.map((button: any) => button.button_text_url);
            const buttonUrlEditUrlValues: string[] = editCarousel.buttons?.button_url.map((button: any) => button.button_url);
            const buttonExampleUrlEditUrlValues: string[] = editCarousel.buttons?.button_url.map((button: any) => button.example_url_values);
            const buttonTextEditPhoneValues: string[] = editCarousel.buttons?.button_phone_number.map((button: any) => button.button_text_phone_number);
            const buttonEditPhoneValues: string[] = editCarousel.buttons?.button_phone_number.map((button: any) => button.button_phone_number);
            setButtonPhoneTextValues(buttonTextEditPhoneValues);
            setButtonPhoneValues(buttonEditPhoneValues);
            setButtonUrlTextValues(buttonTextEditUrlValues);
            setButtonUrlValues(buttonUrlEditUrlValues);
            setButtonExampleUrlValues(buttonExampleUrlEditUrlValues);
          }

          const newCardData = {
            header_format: editCarousel?.header_format || null,
            media_url: editCarousel?.media_url || null,
            body_text: editCarousel?.body_text || null,
            example_body_text: {...editCarousel.example_body_text},
            buttons: editCarousel?.buttons?.buttons_type ? {
              buttons_type: editCarousel?.buttons?.buttons_type,
              button_quick_reply: editCarousel?.buttons?.button_quick_reply,
              button_phone_number: editCarousel?.buttons?.button_phone_number?.length > 0 ? [{
                button_text_phone_number: editCarousel?.buttons.button_phone_number[0]?.button_text_phone_number,
                button_phone_number: editCarousel?.buttons.button_phone_number[0]?.button_phone_number
              }] : null,
              button_url: editCarousel?.buttons?.button_url
            } : null
          };
          onCardDataChange(newCardData);
        });
      }
    }

  }, [editItem]);


  return (
    <>
      <Col xs={24} sm={24} md={24}>
        Card Header
        <Form.Item
          name="card_header_format"
          labelCol={{span: 3}}
          labelAlign="right"
          rules={[
            {
              required: true,
              message: 'Please select header',
            }
          ]}
        >
          <Select
            placeholder="Select a Header"
            onChange={handleHeaderTypeChange}
            disabled={isSaved || !!editItem}
          >
            <Select.Option value="Select Media Type">Media</Select.Option>
            <Select.Option hidden value="IMAGE">Media</Select.Option>
            <Select.Option hidden value="VIDEO">Media</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} className="mt-3">
        {headerType === 'Select Media Type' && (
          <Form.Item
            name="card_header_format"
            labelCol={{span: 3}}
            labelAlign="right"
            rules={[
              {
                required: true,
                message: 'Please enter media type',
              }
            ]}
          >
            <Select placeholder="Select media type" value={headerFormat}
                    onChange={value => setHeaderFormat(value)} disabled={isSaved || !!editItem}>
              <Select.Option value="IMAGE">Image</Select.Option>
              <Select.Option value="VIDEO">Video</Select.Option>
            </Select>
          </Form.Item>
        )}
      </Col><Col xs={24} sm={24} md={24}>
      {headerFormat && ["IMAGE", "VIDEO"].includes(headerFormat) && (
        <Form.Item
          label={<span>
                          Upload sample file{' '}
            <Tooltip className="mx-1"
                     title="To help us understand what kind of message you want to send, you have the option to provide specific content examples for your template. Make sure not to include any actual user or customer information, and provide only sample content in your examples.">
                              <InfoCircleOutlined/>
                          </Tooltip>
                      </span>}
          name="card_media_url"
          rules={[
            {
              required: true,
              message: 'Please upload media',
            }
          ]}>
          <Upload
            name="file"
            accept={headerFormat==="IMAGE"?".jpeg, .jpg, .png":".mp4, .3gp"}
            maxCount={1}
            fileList={fileList}
            customRequest={fileUploadCustomRequest}
            onChange={onChangeFile}
            disabled={isSaved}
            showUploadList={{
              showRemoveIcon: true,
              removeIcon: <DeleteOutlined/>,
            }}
          >
            <Button disabled={isSaved || !!editItem} type='default'
                    className="bg-gray-lightest mx-2 px-5 w-100"><CloudUploadOutlined/>Upload</Button>
          </Upload>
        </Form.Item>)}
    </Col><Col xs={24} sm={24} md={24}>
      Card Body
      <Form.Item
        name="card_body_text"
        labelAlign='left'
        extra="Maximum length is 160 chars"
        labelCol={{span: 3}}
        rules={[
          {
            required: true,
            message: 'Please enter Body text',
          },
          {
            max: 160,
            message: 'Body text cannot exceed 160 characters',
          },
          {
            validator: (rule, value) => {
              if (value && (value.toLowerCase().includes('code') || value.toLowerCase().includes('otp'))) {
                return Promise.reject(new Error('Body text should not contain authentication related keywords like: code, otp, etc'));
              }
              return Promise.resolve();
            },
          },
          {
            validator: (_, value) => {
              const variableRegex = /{{\d+}}/g;
              const variableMatches = value ? value.match(variableRegex) : [];

              if (!variableMatches || variableMatches.length === 0) {
                // If there are no variables, it's valid.
                return Promise.resolve();
              }

              const variableIndexes = variableMatches.map((match: any) => parseInt(match.match(/\d+/)[0], 10)
              );

              // Check if the variables start from {{1}} and are sequential.
              let valid = true;
              for (let i = 1; i <= variableIndexes.length; i += 1) {
                if (variableIndexes[i - 1] !== i) {
                  valid = false;
                  break; // Exit the loop early if an invalid index is found.
                }
              }

              if (!valid) {
                return Promise.reject(new Error('Variables must start from {{1}} and be sequential.'));
              }
              return Promise.resolve();
            },
          }
        ]}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <TextArea
            value={bodyText}
            onChange={(e) => setBodyText(e.target.value)} disabled={isSaved}
            dir={inputDirection}/>
          <Button
            onClick={handleAddVariableToBody}
            style={{
              border: 'none',
              background: 'transparent',
              marginLeft: '8px',
            }}
          >
            Add Variable +
          </Button>
        </div>
      </Form.Item>
    </Col><Col xs={24} sm={24} md={24}>
      {/* Example Data Input for Body Text Variables */}
      {bodyText && bodyText.includes('{{') && (
        <Card
          title={<div style={{display: 'flex', alignItems: 'center'}}>
            Samples for Body Content
            <InfoCircleOutlined
              style={{fontSize: '18px', marginLeft: '8px'}}
              onClick={() => {
                // Show info content (You can use a modal or tooltip)
                Modal.info({
                  title: "Variable Example Information",
                  content: (
                    <div>
                      To help us review your message template,
                      please add an example for each variable in
                      your body text. Do not use real customer
                      information.
                    </div>
                  ),
                });
              }}/>
          </div>}
          style={{
            backgroundColor: 'lightgray',
            marginBottom: '16px',
            textAlign: 'center',
            opacity: "70%"
          }}
        >
          <Form.Item
            name="card_example_body_text"
            labelAlign="left"
            labelCol={{span: 3}}
          >
            {bodyText && bodyText.split('{{').map((part, index) => {
              if (part && part.includes('}}')) {
                const variableIndex: any = part.split('}}')[0];
                return (
                  <Input /* eslint-disable react/no-array-index-key */
                    key={index}
                    name={`card_example_body_text[${variableIndex}]`}
                    dir={inputDirection}
                    placeholder={`Example value for {{${variableIndex}}}`}
                    style={{marginBottom: '8px'}}
                    value={exampleBodyValues[variableIndex]}
                    onChange={(e) => {
                      const newExampleValues = {...exampleBodyValues};
                      newExampleValues[variableIndex] = e.target.value;
                      setExampleBodyValues(newExampleValues);
                    }} disabled={isSaved}/>
                );
              }
              return null;
            })}
          </Form.Item>
        </Card>
      )}
    </Col>
      <Row gutter={24}>
        <>
          <Col xs={24} sm={24} md={24}>
            <p style={{textAlign: 'left'}}>
              <b style={{color: 'red'}}>*** </b>
              <b>At least one button required. The button types must be the same across all cards otherwise template
                will be rejected</b><b style={{color: 'red'}}> ***</b>
            </p>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item labelAlign='left' labelCol={{span: 3}} style={{textAlign: 'center'}}
                       name={buttonsType} // Add a name attribute to identify the field
                       rules={[
                         {
                           required: !buttonsType,
                           message: 'Please select a button type',
                         },
                       ]}>
              <Button.Group>
                <Button
                  type={buttonsType === 'QUICK_REPLY' ? 'primary' : 'default'}
                  onClick={() => handleButtonTypeChange('QUICK_REPLY')}
                  disabled={isSaved}>
                  Quick Replies
                </Button>
                <Button
                  type={buttonsType === 'CALL_TO_ACTION' ? 'primary' : 'default'}
                  onClick={() => handleButtonTypeChange('CALL_TO_ACTION')}
                  disabled={isSaved}>
                  Call to Action
                </Button>
              </Button.Group>
            </Form.Item>
          </Col>


          <Col xs={24} sm={24} md={24}>
            {buttonsType === 'None' && (
              <Form.Item labelAlign='left' labelCol={{span: 3}} label="">
                <Form.Item labelCol={{span: 3}} label="" name={['card_buttons', 'card_buttons_type']} >
                  <Input type="hidden" value="None" disabled={isSaved}/>
                </Form.Item>
              </Form.Item>
            )}
          </Col>
          <Col xs={24} sm={24} md={24}>
            {buttonsType === 'QUICK_REPLY' && (
              <Form.Item labelAlign='left' labelCol={{span: 3}} label="">
                <Form.List name={['card_buttons', 'card_button_quick_reply']}
                           initialValue={['']} // Initialize with an empty string
                >
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 3}}
                            label="Button Text"
                            name={[field.name, 'card_button_text_quick_reply']}
                            fieldKey={[field.fieldKey, 'card_button_text_quick_reply']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Button text',
                              },
                              {
                                max: 25,
                                message: 'Body text cannot exceed 25 characters',
                              }
                            ]}
                          >
                            <Input placeholder="Enter button text"
                                   value={buttonTextValues[index] || ''}
                                   onChange={(e) => handleButtonTextChange(index, e.target.value)} disabled={isSaved}
                                   dir={inputDirection}/>
                          </Form.Item>
                          {/* {index > 0 && ( */}
                            <Button
                              style={{marginBottom: '12px'}}
                              type="dashed"
                              onClick={() => {
                                remove(field.name);
                                // Update state here to remove the corresponding button text
                                const updatedButtonTextValues = [...buttonTextValues];
                                updatedButtonTextValues.splice(index, 1);
                                setButtonTextValues(updatedButtonTextValues);
                              }}
                              block
                              icon={<MinusOutlined/>}
                            >
                              Remove Button
                            </Button>
                          {/* )} */}
                        </div>
                      ))}
                      {fields.length < 2 && (
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>} disabled={isSaved}>
                          Add Button
                        </Button>
                      )}
                    </>
                  )}
                </Form.List>
                {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
              </Form.Item>
            )}
          </Col>


          {buttonsType === 'CALL_TO_ACTION' && (
            <Col xs={24} sm={24} md={24}>
              <Form.Item labelAlign='left' labelCol={{span: 5}} label="Select Action Type"
                         name={['card_buttons', 'card_buttons_type']}>
                <Select placeholder="Select Action type"
                        onChange={(value) => handleActionTypeChange(value)} disabled={isSaved}>
                  <Select.Option value="PHONE_NUMBER">Call phone number</Select.Option>
                  <Select.Option value="URL">Website</Select.Option>
                  <Select.Option value="PHONE_NUMBER_AND_URL">Call phone number & Website</Select.Option>
                </Select>
              </Form.Item>

              <Col>
                {actionsType === 'PHONE_NUMBER_AND_URL' && (
                  <>
                    {fieldsAction.map((field, index) => (
                      <div key={field.key}>
                        <Form.Item
                          labelAlign='left'
                          labelCol={{span: 5}}
                          label="Button Text (Phone Number)"
                          name={['card_buttons', 'card_button_phone_number', index, 'card_button_text_phone_number']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Button text for Phone Number',
                            },
                            {
                              max: 25,
                              message: 'Body text cannot exceed 25 characters',
                            }
                          ]}
                        >
                          <Input
                            placeholder="Enter button text for phone number"
                            value={buttonPhoneTextValues[index] || ''}
                            onChange={(e) => handleButtonPhoneTextChange(index, e.target.value)}
                            disabled={isSaved}
                            dir={inputDirection}/>
                        </Form.Item>
                        <Form.Item
                          labelAlign='left'
                          labelCol={{span: 5}}
                          label="Phone Number"
                          name={['card_buttons', 'card_button_phone_number', index, 'card_button_phone_number']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Phone Number',
                            },
                            {
                              validator: (_, value) => {
                                const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/; // Adjust the regex based on your validation criteria

                                if (value && !phoneNumberRegex.test(value)) {
                                  return Promise.reject(new Error('Please enter a valid phone number'));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input placeholder="Enter phone number"
                                 value={buttonPhoneValues[index] || ''}
                                 onChange={(e) => handleButtonPhoneChange(index, e.target.value)} disabled={isSaved}/>
                        </Form.Item>
                        <Form.Item
                          labelAlign='left'
                          labelCol={{span: 5}}
                          label="Button Text (URL)"
                          name={['card_buttons', 'card_button_url', index, 'card_button_text_url']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Button text for URL',
                            },
                            {
                              max: 25,
                              message: 'Button text cannot exceed 25 characters',
                            }
                          ]}
                        >
                          <Input
                            placeholder="Enter button text for url"
                            value={buttonUrlTextValues[index] || ''}
                            onChange={(e) => handleButtonUrlTextChange(index, e.target.value)}
                            disabled={isSaved}
                            dir={inputDirection}/>
                        </Form.Item>
                        <Form.Item
                          labelAlign='left'
                          labelCol={{span: 5}}
                          label={
                            <span>
                                              URL <InfoCircleOutlined
                              style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                              onClick={() => {
                                Modal.info({
                                  title: "URL",
                                  content: (
                                    <>
                                      <div>
                                        If you wants to add this button as <b>Dynamic URL</b> then add it like <b>https://www.luckyshrub.com/shop?promo={'{{'}1{'}}'}</b> then fill the <b>example
                                        url value</b>. It is mandatory for <b>Dynamic URL</b>.
                                      </div>
                                      <br/>
                                      <div style={{opacity: '0.5'}}>
                                        (Using a <b>Static URL</b>, you can give direct access via a single click
                                        to your website. You can also set up the button to visit a <b>Dynamic
                                        website</b> with your customer directed to a personalized website.)
                                      </div>
                                    </>
                                  ),
                                });
                              }}
                            />
                                          </span>
                          }
                          name={['card_buttons', 'card_button_url', index, 'card_button_url']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Website URL',
                            },
                            {
                              type: 'url',
                              message: 'Please enter a valid URL',
                            },
                            {
                              max: 2000,
                              message: 'URL must be at most 2000 characters',
                            }
                          ]}
                        >
                          <Input placeholder="Enter URL"
                                 value={buttonUrlValues[index] || ''}
                                 onChange={(e) => handleButtonUrlChange(index, e.target.value)} disabled={isSaved}/>
                        </Form.Item>
                        {buttonUrlValues[index]?.includes('{{1}}')  && (
                        <Form.Item
                          labelAlign='left'
                          labelCol={{span: 5}}
                          label={
                            <span>
                                              Example URL Value <InfoCircleOutlined
                              style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                              onClick={() => {
                                Modal.info({
                                  title: "Example URL Value",
                                  content: (
                                    <>
                                      <div>
                                        If you wants to add a <b>Dynamic URL</b> button then fill the <b>example
                                        url value</b>. It is mandatory for <b>Dynamic URL</b>.
                                      </div>
                                      <br/>
                                      <div style={{opacity: '0.5'}}>
                                        (Using a <b>Static URL</b>, you can give direct access via a single click
                                        to your website. You can also set up the button to visit a <b>Dynamic
                                        website</b> with your customer directed to a personalized website.)
                                      </div>
                                    </>
                                  ),
                                });
                              }}
                            />
                                          </span>
                          }
                          name={['card_buttons', 'card_button_url', index, 'card_example_url_values']}
                        >

                          <Input placeholder="Enter Example URL"
                                 value={buttonExampleUrlValues[index] || ''}
                                 onChange={(e) => handleButtonExampleUrlChange(index, e.target.value)} disabled={isSaved}/>
                        </Form.Item>)}
                        {/* {index > 0 && ( */}
                          <Button style={{marginBottom: "12px"}} type="dashed" onClick={() => removeField(index)}
                                  block icon={<MinusOutlined/>}>
                            Remove Button
                          </Button>
                        {/* )} */}
                      </div>
                    ))}
                    {fieldsAction.length < 1 && (
                      <Button type="dashed" onClick={addField} block icon={<PlusOutlined/>} disabled={isSaved}>
                        Add Button
                      </Button>
                    )}
                  </>
                )}
                {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
              </Col>


              <Col xs={24} sm={24} md={24}>
                {actionsType === 'PHONE_NUMBER' && (
                  <Form.Item labelAlign='left' labelCol={{span: 5}} label="">
                    <Form.List name={['card_buttons', 'card_button_phone_number']}
                               initialValue={['']} // Initialize with an empty string
                    >
                      {(fields, {add, remove}) => (
                        <>
                          {fields.map((field, index) => (
                            <div key={field.key}>
                              <Form.Item
                                labelAlign='left'
                                labelCol={{span: 5}}
                                label="Button Text"
                                name={[field.name, 'card_button_text_phone_number']}
                                fieldKey={[field.fieldKey, 'card_button_text_phone_number']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter Button text',
                                  },
                                  {
                                    max: 25,
                                    message: 'Body text cannot exceed 25 characters',
                                  }
                                ]}
                              >
                                <Input placeholder="Enter button text"
                                       value={buttonPhoneTextValues[index] || ''}
                                       onChange={(e) => handleButtonPhoneTextChange(index, e.target.value)} disabled={isSaved}
                                       dir={inputDirection}/>
                              </Form.Item>
                              <Form.Item
                                labelAlign='left'
                                labelCol={{span: 5}}
                                label="Phone Number"
                                name={[field.name, 'card_button_phone_number']}
                                fieldKey={[field.fieldKey, 'card_button_phone_number']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter Phone number',
                                  },
                                  {
                                    validator: (_, value) => {
                                      const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/; // Adjust the regex based on your validation criteria

                                      if (value && !phoneNumberRegex.test(value)) {
                                        return Promise.reject(new Error('Please enter a valid phone number'));
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input placeholder="Enter phone number"
                                       value={buttonPhoneValues[index] || ''}
                                       onChange={(e) => handleButtonPhoneChange(index, e.target.value)} disabled={isSaved}/>
                              </Form.Item>
                              {/* {index > 0 && ( */}
                                <Button
                                  style={{marginBottom: "12px"}}
                                  type="dashed"
                                  onClick={() => {
                                    remove(field.name);
                                    // Update state here to remove the corresponding button text
                                    const updatedPhoneTextValues = [...buttonPhoneTextValues];
                                    updatedPhoneTextValues.splice(index, 1);
                                    setButtonPhoneTextValues(updatedPhoneTextValues);
                                  }}
                                  block
                                  icon={<MinusOutlined/>}
                                >
                                  Remove Button
                                </Button>
                              {/* )} */}
                            </div>
                          ))}
                          {fields.length < 1 && (
                            <Button type="dashed" onClick={() => add()} block
                                    icon={<PlusOutlined/>} disabled={isSaved}>
                              Add Button
                            </Button>
                          )}
                        </>
                      )}
                    </Form.List>
                    {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                  </Form.Item>
                )}
              </Col>
            </Col>

          )}
          <Col xs={24} sm={24} md={24}>
            {actionsType === 'URL' && (
              <Form.Item labelAlign='left' labelCol={{span: 5}} label="">
                <Form.List name={['card_buttons', 'card_button_url']}
                           initialValue={['']} // Initialize with an empty string
                >
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 5}}
                            label="Button Text"
                            name={[field.name, 'card_button_text_url']}
                            fieldKey={[field.fieldKey, 'card_button_text_url']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Button text',
                              },
                              {
                                max: 25,
                                message: 'Body text cannot exceed 25 characters',
                              }
                            ]}
                          >
                            <Input placeholder="Enter button text"
                                   value={buttonUrlTextValues[index] || ''}
                                   onChange={(e) => handleButtonUrlTextChange(index, e.target.value)} disabled={isSaved}
                                   dir={inputDirection}/>
                          </Form.Item>
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 5}}
                            label={
                              <span>
                                              URL <InfoCircleOutlined
                                style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                                onClick={() => {
                                  Modal.info({
                                    title: "URL",
                                    content: (
                                      <>
                                        <div>
                                          If you wants to add this button as <b>Dynamic URL</b> then add it like <b>https://www.luckyshrub.com/shop?promo={'{{'}1{'}}'}</b> then fill the <b>example
                                          url value</b>. It is mandatory for <b>Dynamic URL</b>.
                                        </div>
                                        <br/>
                                        <div style={{opacity: '0.5'}}>
                                          (Using a <b>Static URL</b>, you can give direct access via a single click
                                          to your website. You can also set up the button to visit a <b>Dynamic
                                          website</b> with your customer directed to a personalized website.)
                                        </div>
                                      </>
                                    ),
                                  });
                                }}
                              />
                                          </span>
                            }
                            name={[field.name, 'card_button_url']}
                            fieldKey={[field.fieldKey, 'card_button_url']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Button URL value',
                              },
                              {
                                type: 'url',
                                message: 'Please enter a valid URL',
                              },
                              {
                                max: 2000,
                                message: 'URL must be at most 2000 characters',
                              }
                            ]}
                          >
                            <Input placeholder="Enter Url"
                                   value={buttonUrlValues[index] || ''}
                                   onChange={(e) => handleButtonUrlChange(index, e.target.value)} disabled={isSaved}/>
                          </Form.Item>
                          {buttonUrlValues[index]?.includes('{{1}}')  && (
                          <Form.Item
                            labelAlign='left'
                            labelCol={{span: 5}}
                            label={
                              <span>
                                              Example URL Value <InfoCircleOutlined
                                style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                                onClick={() => {
                                  Modal.info({
                                    title: "Example URL Value",
                                    content: (
                                      <>
                                        <div>
                                          If you wants to add a <b>Dynamic URL</b> button then fill the <b>example
                                          url value</b>. It is mandatory for <b>Dynamic URL</b>.
                                        </div>
                                        <br/>
                                        <div style={{opacity: '0.5'}}>
                                          (Using a <b>Static URL</b>, you can give direct access via a single
                                          click to your website. You can also set up the button to visit a <b>Dynamic
                                          website</b> with your customer directed to a personalized website.)
                                        </div>
                                      </>
                                    ),
                                  });
                                }}
                              />
                                          </span>
                            }
                            name={[field.name, 'card_example_url_values']}
                            fieldKey={[field.fieldKey, 'card_example_url_values']}
                          >

                            <Input placeholder="Enter Example Url"
                                   value={buttonExampleUrlValues[index] || ''}
                                   onChange={(e) => handleButtonExampleUrlChange(index, e.target.value)} disabled={isSaved}/>
                          </Form.Item>)}
                          {/* {index > 0 && ( */}
                            <Button
                              style={{marginBottom: "12px"}}
                              type="dashed"
                              onClick={() => {
                                remove(field.name);
                                // Update state here to remove the corresponding button text
                                const updatedUrlTextValues = [...buttonUrlTextValues];
                                updatedUrlTextValues.splice(index, 1);
                                setButtonUrlTextValues(updatedUrlTextValues);
                              }}
                              block
                              icon={<MinusOutlined/>}
                            >
                              Remove Button
                            </Button>
                          {/* )} */}
                        </div>
                      ))}
                      {fields.length < 2 && (
                        <Button type="dashed" onClick={() => add()} block
                                icon={<PlusOutlined/>} disabled={isSaved}>
                          Add Button
                        </Button>
                      )}
                    </>
                  )}
                </Form.List>
                {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
              </Form.Item>
            )}
          </Col>
        </>
      </Row>
      <Button className="bg-gray-lighter" type="default" onClick={addCard} disabled={uploadingFile || isSaved}>
        <SaveOutlined/>Save Card
      </Button>
    </>
  );
};

StepTwoCarouselComponent.defaultProps = {
  editItem: undefined,
  inputDirection: undefined,
};


export default StepTwoCarouselComponent;
